<template>
	<div class="title">
		<el-divider content-position="left">统计</el-divider>
		<el-row :gutter="12">
			<el-col :span="8">
				<el-card shadow="hover">
					<div class="left" style="background: #e77;">
						<div class="icon">
							<el-icon color="white" size="30" style="margin-top: 10px;"><Avatar /></el-icon>
						</div>
						<div class="text">用户</div>
					</div>
					<div class="right">{{ index1_census.user_count }}</div>
				</el-card>
			</el-col>
			<el-col :span="8">
				<el-card shadow="hover">
					<div class="left" style="background: rgb(119, 149, 238);">
						<div class="icon">
							<el-icon color="white" size="30" style="margin-top: 10px;"><Goods /></el-icon>
						</div>
						<div class="text">商品</div>
					</div>
					<div class="right">{{ index1_census.goods_count }}</div>
				</el-card>
			</el-col>
			<el-col :span="8">
				<el-card shadow="hover">
					<div class="left" style="background: rgb(131, 213, 179);">
						<div class="icon">
							<el-icon color="white" size="30" style="margin-top: 10px;"><Money /></el-icon>
						</div>
						<div class="text">总额</div>
					</div>
					<div class="right">{{ index1_census.order_price }}</div>
				</el-card>
			</el-col>
			<el-col :span="12" style="margin-top: 10px;">
				<el-card shadow="hover">
					<div class="left" style="background: rgb(56, 211, 213);text-align: left;">
						<div class="icon">
							<el-icon color="white" size="30" style="margin-top: 10px;"><Money /></el-icon>
						</div>
						<div class="text" style="text-align: left;font-size: 18px;">总库存</div>
					</div>
					<div class="right">{{ index1_census.count_stock }}</div>
				</el-card>
			</el-col>
			<el-col :span="12" style="margin-top: 10px;">
				<el-card shadow="hover">
					<div class="left" style="background: rgb(213, 92, 130);">
						<div class="icon" style="font-size: 18px;">
							<el-icon color="white" size="30" style="margin-top: 10px;"><Money /></el-icon>
						</div>
						<div class="text" style="text-align: left;font-size: 18px;">库存金额</div>
					</div>
					<div class="right">{{ index1_census.count_price }}</div>
				</el-card>
			</el-col>
		</el-row>
	</div>
	<el-divider content-position="left">人员下单看板</el-divider>
	<div style="overflow: hidden;">
		<div style="text-align: center;">
			<el-config-provider :locale="locale">
				<el-date-picker v-model="ry_date_time" type="daterange" start-placeholder="开始时间" end-placeholder="截至时间" value-format="YYYY-MM-DD" />
			</el-config-provider>
			<el-button type="primary" style="margin-left: 10px;" @click="index2">搜索</el-button>
		</div>

		<div id="rank_img" class="rank_img"></div>
		<div></div>
	</div>
	<el-divider content-position="left">营业额</el-divider>
	<el-row class="mb-4"></el-row>
	<div style="text-align: center;">
		<el-config-provider :locale="locale">
			<el-date-picker v-model="yye_date_time" type="daterange" start-placeholder="开始时间" end-placeholder="截至时间" value-format="YYYY-MM-DD" />
		</el-config-provider>
		<el-button type="primary" style="margin-left: 10px;" @click="index3">搜索</el-button>
		<span style="color: red;">不选时间默认近7天</span>
	</div>
	<div id="price_img" class="price_img"></div>
</template>

<script setup>
import * as echarts from 'echarts';
import { onMounted, ref, inject } from 'vue';
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
const locale = zhCn;
const $error = inject('$error');
const $get = inject('$get');
const $post = inject('$post');
const yye_date_time = ref('');
const ry_date_time = ref('');
const index1_census = ref({ user_count: 0, goods_count: 0, order_price: 0 });
const index2_census = ref('');
const index3_census = ref('');
onMounted(() => {
	setTimeout(() => {
		// census();
		// rank();
	}, 1);
	index1();
	index2();
	index3();
});
const index1 = async () => {
	const res = await $get('/api/Census/index1', '');
	index1_census.value = res.result;
};
const index2 = async () => {
	const res = await $get('/api/Census/index2', { ry_date_time: ry_date_time.value });
	index2_census.value = res.result;
	rank();
};
const index3 = async () => {
	const res = await $get('/api/Census/index3', { yye_date_time: yye_date_time.value });
	if (res.errCode == '0') {
		$error(res.msg);
		return;
	}
	index3_census.value = res.result;
	census();
	// rank();
};

const search = () => {
	console.log(date_time.value);
};
const census = () => {
	var myChart = echarts.init(document.getElementById('price_img'));
	const option = {
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				type: 'cross',
				label: {
					backgroundColor: '#6a7985'
				}
			}
		},
		legend: {
			data: ['day']
		},
		toolbox: {
			feature: {
				saveAsImage: {}
			}
		},
		grid: {
			left: '3%',
			right: '4%',
			bottom: '3%',
			containLabel: true
		},
		xAxis: [
			{
				type: 'category',
				boundaryGap: false,
				data: index3_census.value.label
			}
		],
		yAxis: [
			{
				type: 'value'
			}
		],
		series: [
			{
				name: '金额',
				type: 'line',
				stack: 'Total',
				areaStyle: {},
				emphasis: {
					focus: 'series'
				},
				data: index3_census.value.value
			}
		]
	};
	myChart.setOption(option);
};
const rank = () => {
	var myChart = echarts.init(document.getElementById('rank_img'));
	const option = {
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				// Use axis to trigger tooltip
				type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
			}
		},
		legend: {},
		grid: {
			left: '3%',
			right: '4%',
			bottom: '3%',
			containLabel: true
		},
		xAxis: {
			type: 'value'
		},
		yAxis: {
			type: 'category',
			data: index2_census.value.user
		},
		dataZoom: [
			//Y轴滑动条
			{
				type: 'slider', //滑动条
				show: true, //开启
				yAxisIndex: [0],
				left: '93%', //滑动条位置
				start: 1, //初始化时，滑动条宽度开始标度
				end: 50 //初始化时，滑动条宽度结束标度
			},
			//y轴内置滑动
			{
				type: 'inside', //内置滑动，随鼠标滚轮展示
				yAxisIndex: [0],
				start: 1, //初始化时，滑动条宽度开始标度
				end: 50 //初始化时，滑动条宽度结束标度
			}
		],
		series: [
			{
				// name: 'Direct',
				type: 'bar',
				stack: 'total',
				label: {
					show: true
				},
				emphasis: {
					focus: 'series'
				},
				yAxis: {
					type: 'category',
					show: true
				},
				data: index2_census.value.price
			}
		]
	};
	myChart.setOption(option);
};
</script>
<style>
.el-card__body {
	padding: 0px !important;
}
</style>
<style lang="scss" scoped>
.title {
	padding: 20px;
	.left {
		width: 30%;
		height: 50px;
		text-align: center;
		float: left;
		.icon {
			width: 40%;
			float: left;
			text-align: right;
		}
		.text {
			width: 55%;
			float: left;
			line-height: 50px;
			color: white;
			font-size: 25px;
			text-align: left;
			margin-left: 5%;
		}
	}
	.right {
		width: 70%;
		text-align: center;
		line-height: 50px;
		float: left;
		font-size: 30px;
		font-weight: 700;
	}
}
.price_img {
	width: 100%;
	height: 300px;
}
.rank_img {
	width: 100%;
	height: 300px;
	float: left;
}
</style>
